import * as React from "react"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
	 <Helmet>
        <script src={'/js/main.js'} type="text/javascript"/>
      </Helmet>
    <Seo title="404: Not found" />
   <section className="wrap center"><img src={'/images/404.svg'} width="662" style={{maxWidth:"90%"}} alt="Sorry, page not found." /><p style={{fontSize:"18px"}}>The page you requested may have been <br className="onlyDesktop"/>removed or be temporarily unavailable.</p></section>
  </Layout>
)

export default NotFoundPage
 